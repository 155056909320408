/* src/components/LoadingPopup.css */

.approved-popup {
    width: 17%;
    height: 250px;
    background: rgb(74, 97, 74);
    padding: 20px;
    color: #ffff;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ffffff35;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .approved-popup-content img {
    width: 100%;
    justify-content: center;
    align-items: center;

  }
  
  .approved-popup-content p {
    width: 100%;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family); 
    margin-left: 1.2rem;
    margin-bottom: 2rem;
  }


  @media screen and (max-width: 2560px) {
    .approved-popup {
      width: 20%;
      height: 500px;
    }
  }


  @media screen and (max-width: 1585px) {
    .approved-popup {
      width: 17%;
      height: 250px;
    }
  }
    
  @media screen and (max-width: 1025px) {
    .approved-popup {
      width: 30%;
      height: 300px;
    
    }
  }
  
  @media screen and (max-width: 500px) {
    .approved-popup {
      width: 60%;
      height: 300px;
    
    }
  }