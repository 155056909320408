/* src/components/LoadingPopup.css */

.loading-popup {
  width: 17%;
  height: 300px;
  background: rgb(74, 97, 74);
  padding: 20px;
  color: #ffff;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #ffffff35;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.loading-popup-content img {
  width: 100%;
  justify-content: center;
  align-items: center;
  animation: rotate 2s linear infinite;
}

.loading-popup-content p {
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family); 
  margin-left: 1.2rem;
  margin-bottom: 2rem;
}

/* Define the rotation animation */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1025px) {
  .loading-popup {
    width: 30%;
    height: 300px;
  
  }
}

@media screen and (max-width: 500px) {
  .loading-popup {
    width: 60%;
    height: 300px;
  
  }
}

