.forms_burn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  /* Seção de Fundo e Formulário */
  .forms_burn_background {
    
    width: 100%;
    color: rgb(0, 0, 0);
  }

  
  .forms_burn_content {
    max-width: 600px;
    margin: 0 auto;
    padding-top: 1rem;
    padding-bottom: 5rem ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  
  .forms_burn_content h1 {
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: bold;

  }

  .forms_burn_division {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 5px;
    border-radius: 20px;
    border: none;
    background-color: #000000;
    margin-bottom: 1rem;

}
  
  .forms_burn_content label {
    display: block;
    margin: 10px 0 5px;
    max-width: 95%;
  }
  
  .forms_burn_content input,
  .forms_burn_content textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .forms_burn_content input:focus,
  .forms_burn_content textarea:focus {
    border-color: #458017ca;
    outline: none;
  }
  
  .submit_button_burn {
    background-color: #458017ca;
    border: none;
    color: white;
    font-size: 1.3rem;
    font-weight: bold;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .submit_button_burn:hover {
    background-color: #45801793;
  }


  @media screen and (max-width: 1540px) {
    .forms_banner_image {
        width: 100%; /* A imagem ocupará toda a largura do contêiner */
        height: 400px; /* Ajuste a altura conforme necessário */
        object-fit: cover; /* Garante que a imagem se ajuste ao contêiner sem distorção */
        border-radius: 10px;
      }
      
      
      /* Estilos para o texto sobreposto */
      .imageTextOverlay {
        top: 6.5rem; /* Ajuste a margem superior conforme necessário */
        left: 1.7rem; /* Margem da esquerda */
        padding: 1rem;
    
      }
      
      .imageTextOverlay h2 {
        margin: 0 0 0.5rem;
        font-size: 1.8rem;
        
      }
      
      .desktopText p {
        font-size: 1.3rem;
        margin-top: 1rem;
      }
      .cta_button {
        margin-top: 1.5rem;
        transition: background-color 0.3s ease;
      }
    

  }

  @media screen and (max-width: 1250px) {
    
    .forms_burn_content input,
    .forms_burn_content textarea {
      width: 95%;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ddd;
      border-radius: 5px;
    }
    
      
      
    

  }

  @media screen and (max-width: 985px) {
    .forms_banner_image {
        width: 100%; /* A imagem ocupará toda a largura do contêiner */
        height: 250px; /* Ajuste a altura conforme necessário */
      }
      
      
      /* Estilos para o texto sobreposto */
      .imageTextOverlay {
        top: 3.5rem; /* Ajuste a margem superior conforme necessário */
        left: 1rem; /* Margem da esquerda */
        padding: 1rem;
    
      }
      
      .imageTextOverlay h2 {
        margin: 0 0 0.5rem;
        font-size: 1.5rem;
        
      }
      
      .desktopText p {
        font-size: 0.9rem;
        margin-top: 0.7rem;
      }
      .cta_button {
        margin-top: 0.7rem;
        transition: background-color 0.3s ease;
        font-size: 0.7rem;
      }
    

  }

  @media screen and (max-width: 740px) {
    .forms_banner_image {
        width: 100%; /* A imagem ocupará toda a largura do contêiner */
        height: 180px; /* Ajuste a altura conforme necessário */
      }
      
      
      /* Estilos para o texto sobreposto */
      .imageTextOverlay {
        top: 1.8rem; /* Ajuste a margem superior conforme necessário */
        left: 0rem; /* Margem da esquerda */
        padding: 1rem;
    
      }
      
      .imageTextOverlay h2 {
        margin: 0 0 0.5rem;
        font-size: 1rem;
        
      }
      
      .desktopText p {
        font-size: 0.65rem;
        margin-top: 0.5rem;
      }
      .cta_button {
        margin-top: 0.5rem;
        transition: background-color 0.3s ease;
        font-size: 0.50rem;
      }
    

  }

  @media screen and (max-width: 530px) {
    .forms_banner_image {
      width: 80%; /* A imagem ocupará toda a largura do contêiner */
      height: 250px; /* Ajuste a altura conforme necessário */
    }
      
      /* Estilos para o texto sobreposto */
      .imageTextOverlay {
        top: 5rem; /* Ajuste a margem superior conforme necessário */
        left: 2.4rem; /* Margem da esquerda */
        padding: 1rem;
    
      }
      
      .imageTextOverlay h2 {
        margin: 0 0 0.1rem;
        font-size: 0.85rem;
        
      }
      
      .desktopText p {
        display: none;
      }

      .mobileText p {
        display: block;
        margin-top: 1rem;
        font-size: 0.8rem;
        max-width: 160px;
      }
      .cta_button {
        background-color: #458017ca;
        font-weight: bold;
        border: none;
        color: white;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
        margin-top: 2rem;
        transition: background-color 0.3s ease;
      }
      .forms_container_content {
        margin-top: -3rem;
        
      }
    

  }

  @media screen and (max-width: 376px) {
    .forms_banner_image {
        width: 80%; /* A imagem ocupará toda a largura do contêiner */
        height: 250px; /* Ajuste a altura conforme necessário */
      }
      
      
      /* Estilos para o texto sobreposto */
      .imageTextOverlay {
        top: 5rem; /* Ajuste a margem superior conforme necessário */
        left: 2rem; /* Margem da esquerda */
        padding: 1rem;
    
      }
      
      .imageTextOverlay h2 {
        margin: 0 0 0.1rem;
        font-size: 0.7rem;
        
      }
      
      .desktopText p {
        display: none;
      }

      .mobileText p {
        display: block;
        margin-top: 1.5rem;
        font-size: 0.7rem;
        max-width: 100px;
      }
      .cta_button {
        margin-top: 2.5rem;
        transition: background-color 0.3s ease;
        font-size: 0.55rem;
      }

      .forms_container_content {
        margin-top: -3rem;
        
      }
      
    

  }


